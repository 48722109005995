import React from "react";
import { PertConfig } from "API";
import { HGForm, HGToggleSubmit } from "common/HGForm";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import * as yup from "yup";
import { HGNumber, HGSelect } from "common/HGForm/Inputs";

export const PHIRecordsInput: React.FC<{ assets?: any }> = ({ assets }) => {
  const PHIRecord =
    (assets?.PHIRecords && JSON.parse(assets?.PHIRecords)) || "";
  const confidenceValues = [
    { id: 4, title: "Low" },
    { id: 8, title: "Medium" },
    { id: 16, title: "High" },
  ];
  return (
    <Card>
      <Card.Header>
        <HGToggleSubmit className="float-right" applyDirty />
        PHI Records
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm={3}>
            <HGNumber
              label="Min"
              name="min"
              defaultName="min"
              defaultValue={PHIRecord?.min}
            />
          </Col>
          <Col sm={3}>
            <HGNumber
              label="Most Likely"
              name="mode"
              defaultName="mode"
              defaultValue={PHIRecord?.mode}
            />
          </Col>
          <Col sm={3}>
            <HGNumber
              label="Max"
              name="max"
              defaultName="max"
              defaultValue={PHIRecord?.max}
            />
          </Col>
          <Col sm={3}>
            <HGSelect
              label="Confidence"
              name="confidence"
              defaultValue={PHIRecord?.confidence || confidenceValues[0].id}
              options={confidenceValues}
              overrideFormat={
                confidenceValues?.find(
                  (item) => item.id === PHIRecord?.confidence
                )?.title ?? ""
              }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const AssetsPHIRecordInputForm: React.FC<{
  mutationModifyAssets: any;
  assets?: any;
}> = ({ mutationModifyAssets, assets }) => {
  const { id } = useParams<{ id: string; tab: string }>();
  const pertInputObject = yup
    .number()
    .min(0)
    .typeError("${path} requires a number")
    .required()
    .typeError("${path} is required.")
    .default(0);

  const pertSchema = yup.object<PertConfig>().shape({
    min: pertInputObject
      .label("Min")
      .test(
        "is-less",
        "${path} must be less than Most Likely and Max",
        function (value) {
          return (
            value !== undefined &&
            value !== null &&
            this.parent.mode >= value &&
            this.parent.max >= value
          );
        }
      ),
    mode: pertInputObject
      .label("Most Likely")
      .test(
        "is-between",
        "${path} must be between Min and Max",
        function (value) {
          return (
            value !== undefined &&
            value !== null &&
            this.parent.min <= value &&
            this.parent.max >= value
          );
        }
      ),
    max: pertInputObject
      .label("Max")
      .test(
        "is-greater",
        "${path} must be greater than Most Likely and Max",
        function (value) {
          return (
            value !== undefined &&
            value !== null &&
            this.parent.min <= value &&
            this.parent.mode <= value
          );
        }
      ),
    confidence: yup.number().required().default(4).label("Confidence"),
  });
  return (
    <HGForm
      onSubmit={async (input) => {
        return mutationModifyAssets({
          variables: {
            input: {
              PHIRecords: JSON.stringify(input),
              id,
            },
          },
        });
      }}
      editToggle
      resolver={pertSchema}
    >
      <PHIRecordsInput assets={assets} />
    </HGForm>
  );
};
