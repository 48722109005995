import { useQuery, gql, useLazyQuery } from "@apollo/client";
import {
  GetActionPlanChangesInGroupQuery,
  GetActionPlanChangesInGroupQueryVariables,
  GetActionPlanv3Query,
  GetActionPlanv3QueryVariables,
  GetActionPlansInGroupv3Query,
  GetActionPlansInGroupv3QueryVariables,
  GetOwnedActionPlansQuery,
  GetOwnedActionPlansQueryVariables,
  GetOwnedTasksQuery,
  GetOwnedTasksQueryVariables,
  EffortType,
  GetTasksInActionPlanv3Query,
  GetTasksInActionPlanv3QueryVariables,
} from "API";
import { usePermissions } from "common/Permissions";
import {
  getActionPlanv3,
  getActionPlanChangesInGroup,
  getActionPlansInGroupv3,
  getOwnedActionPlans,
  getOwnedTasks,
  getTasksInActionPlanv3,
} from "graphql/queries";
import { DateTime } from "luxon";
import { useGetGroupByRelatedItem } from "utils/useGetGroupByRelatedItems";
import { useGroupCheck } from "utils/useGroupCheck";
import {
  useProtectedPolling,
  useProtectedQuery,
} from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";
import { formatStatus } from "utils/formatStatus";
import { getCompositeId, getItemApprover } from "utils/dataFormatters";
import { useParams } from "react-router";

const actionPlansInGroupTransform = (response) => {
  return (
    response.data?.getActionPlansInGroupv3?.items.map(
      (item) => item.actionplan
    ) || []
  )
    .filter((item) => item?.isTemplate !== true)
    .map(sortToID("ACTIONPLAN#"));
};

export const useGetOwnedActionPlans = () => {
  const { group, hasGroup, userId } = usePermissions();

  const response = useProtectedQuery<
    GetOwnedActionPlansQuery,
    GetOwnedActionPlansQueryVariables
  >(
    gql(getOwnedActionPlans),
    {
      variables: {
        id: userId,
      },
    },
    hasGroup
  );

  const actionPlans = (response.data?.getOwnedActionPlans?.items || [])
    .map(({ actionplan }) => actionplan)
    .map(sortToID("ACTIONPLAN#"));

  return { ...response, actionPlans };
};

export const useGetActionPlansInSelectedGroup = () => {
  const [_getActionPlansInSelectedGroup, response] = useLazyQuery<
    GetActionPlansInGroupv3Query,
    GetActionPlansInGroupv3QueryVariables
  >(gql(getActionPlansInGroupv3));

  return (id) =>
    _getActionPlansInSelectedGroup(id).then(actionPlansInGroupTransform);
};

export const useGetOwnedTasks = () => {
  const { group, hasGroup, userId } = usePermissions();

  const response = useProtectedQuery<
    GetOwnedTasksQuery,
    GetOwnedTasksQueryVariables
  >(
    gql(getOwnedTasks),
    {
      variables: {
        id: userId,
      },
    },
    hasGroup
  );

  const tasks = (response.data?.getOwnedTasks?.items || [])
    .map(({ task }) => task)
    .map((item) => {
      if (item?.status === "Closed_Pending_Approval") {
        return {
          ...item,
          status: "Closed",
        };
      }
      return item;
    })
    .map(sortToID("TASK#"));

  return { ...response, tasks };
};

export const useGetActionPlansInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetActionPlansInGroupv3Query,
    GetActionPlansInGroupv3QueryVariables
  >(
    gql(getActionPlansInGroupv3),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const actionPlans = (
    response.data?.getActionPlansInGroupv3?.items.map(
      (item) => item.actionplan
    ) || []
  )
    .filter((item) => item?.isTemplate !== true)
    .map(sortToID("ACTIONPLAN#"));

  const actionPlansTemplates = (
    response.data?.getActionPlansInGroupv3?.items.map(
      (item) => item.actionplan
    ) || []
  )
    .filter((item) => item?.isTemplate === true)
    .map(sortToID("ACTIONPLAN#"));

  return { ...response, actionPlans, actionPlansTemplates };
};

export const useGetActionPlan = (id) => {
  const { group, hasGroup } = usePermissions();
  const response = useProtectedQuery<
    GetActionPlanv3Query,
    GetActionPlanv3QueryVariables
  >(
    gql(getActionPlanv3),
    {
      variables: {
        groupID: group.id,
        id,
      },
    },
    hasGroup
  );

  const actionPlan = sortToID("ACTIONPLAN#")(response.data?.getActionPlanv3);

  useProtectedPolling(actionPlan, response);

  const canAccess = useGroupCheck(actionPlan?.groupID, "/actionplans");

  return { ...response, actionPlan: canAccess && actionPlan };
};

export const useGetActionPlanChangesInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetActionPlanChangesInGroupQuery,
    GetActionPlanChangesInGroupQueryVariables
  >(
    gql(getActionPlanChangesInGroup),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const changes = (response.data?.getActionPlanChangesInGroup?.items || [])
    .map(sortToID("CHANGELOG#ACTIONPLAN#"))
    .sort(
      (a, b) =>
        DateTime.fromISO(b.createdAt).toMillis() -
        DateTime.fromISO(a.createdAt).toMillis()
    );

  return { ...response, changes };
};

export const useGetActionPlanTemplatesInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetActionPlansInGroupv3Query,
    GetActionPlanChangesInGroupQueryVariables
  >(
    gql(getActionPlansInGroupv3),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const actionPlanTemplates: Array<GetActionPlanv3Query["getActionPlanv3"]> = (
    response.data?.getActionPlansInGroupv3?.items.map(
      (item) => item.actionplan
    ) || []
  )

    .filter((item) => item?.isTemplate)
    .map(sortToID("ACTIONPLAN#"))
    .map(({ ...actionplan }) => ({
      ...actionplan,
      relationType: "actionplan",
    }));
  return { ...response, actionPlanTemplates };
};

export const useGetDownloadActionPlanObj = (item, files) => {
  const { getOrganizationUser, groups } = usePermissions();
  const actionPlanArray = [
    "title",
    "status",
    "createdAt",
    "lastModified",
    "effortAmount",
    "description",
    "resources",
    "notes",
    "owner",
    "priority",
    "type",
    "startDate",
    "dueDate",
    "reviewDate",
    "tasks",
    "relatedItems",
    "approver",
  ];
  const selectArray = (arr, obj) =>
    arr.reduce((r, e) => Object.assign(r, obj[e] ? { [e]: obj[e] } : null), {});
  const taskArray = [] as any[];
  item.taskChildren.items.map((item) => {
    taskArray.push({
      Title: item.task.title,
      Status: item.task.status,
      Priority: item.task.priority,
      DueDate: item.task.dueDate,
    });
  });
  const theads = Object.keys(Object.assign({}, ...taskArray));
  const taskTableString: any = `<table><tbody><tr>${theads
    .map(
      (i) =>
        `<th colspan="1" rowspan="1"><p>${i
          .replace(/([A-Z])/g, " $1")
          .trim()}<p/></th>`
    )
    .join("")}</tr>${taskArray
    .map((i) => {
      return `<tr>${Object.values(i)
        .map((val) => {
          return `<td colspan="1" rowspan="1">${val}</td>`;
        })
        .join("")}</tr>`;
    })
    .join("")}<tbody></table>`;
  taskTableString.replace(/,/g, "");

  const groupedRelatedItems = useGetGroupByRelatedItem(
    item.actionPlanRelatedItems.items,
    groups
  );
  item = {
    ...item,
    title: `${item.title} (${getCompositeId(item)})`,
    status: formatStatus(item?.status),
    type: item?.type ?? "",
    approver: getItemApprover(item, "actionplanApprovers"),
    effortAmount:
      item?.effortAmount === -1 || item?.effortAmount === null
        ? "Not Set"
        : `${item?.effortAmount} ${
            item?.effortType || Object.values(EffortType)[0]
          }`,
    createdAt: DateTime.fromISO(item?.createdAt)
      .toLocaleString(DateTime.DATETIME_MED)
      .replace(/,/g, ""),
    lastModified: DateTime.fromISO(item?.lastModified)
      .toLocaleString(DateTime.DATETIME_MED)
      .replace(/,/g, ""),
    owner: getOrganizationUser(item?.owner)?.displayName,
    tasks: taskArray.length > 0 ? taskTableString : "",
    relatedItems: groupedRelatedItems || " ",
  };
  return selectArray(actionPlanArray, item);
};

export const useGetActionPlanTasks = () => {
  const { id } = useParams<{ id: string }>();
  const response = useQuery<
    GetTasksInActionPlanv3Query,
    GetTasksInActionPlanv3QueryVariables
  >(gql(getTasksInActionPlanv3), {
    variables: {
      id,
    },
    errorPolicy: "ignore",
  });
  const tasks = (
    response.data?.getTasksInActionPlanv3?.items.map((item) => item.task) || []
  )
    .map((item) => {
      if (item?.status === "Closed_Pending_Approval") {
        return {
          ...item,
          status: "Closed",
        };
      }
      return item;
    })
    .map(sortToID("TASK#"));

  return { ...response, tasks };
};
